import { Vocab } from "./Vocab";

export class DataProvider {
  getRandomVocab(vocabList: Array<Vocab>, excludeVocabs?: Array<Vocab>) {
    let filteredVocabList = vocabList.filter((vocab) => {
      if (!excludeVocabs || !excludeVocabs.includes(vocab)) {
        return vocab;
      }
    });
    let vocabId = Math.floor(Math.random() * filteredVocabList.length);

    return filteredVocabList[vocabId];
  }
}
