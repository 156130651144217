import { DataProvider } from "./DataProvider";
import { QuestionType } from "./QuestionType";
import { Vocab } from "./Vocab";

export class Level {
  constructor(vocabList: Array<Vocab>) {
    let dataProvider = new DataProvider();

    // randomly select one vocab for the question
    this.questionedVocab = dataProvider.getRandomVocab(vocabList);

    // randomly select 3 false answeres as alternatives
    for (let i = 0; i < 3; i++) {
      this.falseAnswers.push(
        dataProvider.getRandomVocab(vocabList, [
          this.questionedVocab,
          ...this.falseAnswers,
        ])
      );
    }

    // combine and mix answers for display
    this.mixedAnswers = [this.questionedVocab, ...this.falseAnswers];
    this.shuffleArray(this.mixedAnswers);

    // randomly select question type
    this.questionType =
      this.possibleQuestionTypes[
        Math.floor(Math.random() * this.possibleQuestionTypes.length)
      ];

    // set question human readable
    this.setQuestion();
  }

  setQuestion = function () {
    if (this.questionType == QuestionType.AskForEnglish) {
      this.question = this.questionedVocab.german + " in 🇦🇺?";
    } else {
      this.question = "" + this.questionedVocab.english + " in 🇩🇪?";
    }
  };

  showAnswers = function () {
    this.answersVisible = true;
  };

  solve = function (correctAnswer: boolean) {
    if (correctAnswer) {
      window.navigator.vibrate([100]);
    } else {
      window.navigator.vibrate([100, 400, 100]);
    }

    this.correctnessVisible = true;
  };

  shuffleArray = function (array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  answersVisible: boolean = false;
  correctnessVisible: boolean = false;
  question: String = "";
  questionedVocab: Vocab;
  falseAnswers: Array<Vocab> = [];
  mixedAnswers: Array<Vocab> = [];

  questionType: QuestionType;
  possibleQuestionTypes: Array<QuestionType> = [
    QuestionType.AskForEnglish,
    QuestionType.AskForGerman,
  ];
}
