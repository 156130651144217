export class FileHandler {
  exportDataFile(data) {
    var file = new Blob([JSON.stringify(data)], { type: "application/vocab" });

    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = "export.vocab";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  importDataFile() {
    return new Promise((resolve, reject) => {
      launchQueue.setConsumer((launchParams) => {
        for (const file of launchParams.files) {
          file.getFile().then((blob) => {
            blob.handle = file;
            blob.text().then((content) => {
              try {
                let parsedContent = JSON.parse(content);
                if (
                  confirm(
                    "Do you want to import vocabulary? Data will be appended!"
                  )
                ) {
                  resolve(parsedContent);
                } else {
                  reject();
                }
              } catch (e) {
                reject(e);
              }
            });
          });
        }
      });
    });
  }
}
