import { DataProvider } from "./DataProvider";
import { FileHandler } from "./FileHandler";
import { Level } from "./Level";

export function game() {
  return {
    init: function () {
      this.dataProvider = new DataProvider();
      this.fileHandler = new FileHandler();

      this.nextLevel();

      if ("launchQueue" in window) {
        this.fileHandler
          .importDataFile()
          .then((content) => (this.vocabs = [...this.vocabs, ...content]));
      }
    },
    nextLevel: function () {
      if (this.vocabs.length >= 4) {
        this.level = new Level(this.vocabs);
      }
    },
    level: null,
    dataProvider: null,
    showSettings: false,
    vocabs: this.$persist([]),
    fileHandler: null,
  };
}
